//import Card from "../components/Card";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import PropertyItem from "../components/PropertyItem";
//import img1 from '../images/houses/h1.jpeg';
import { useEffect, useState } from "react";
import propertyDataService from "../services/property.service";
import Property from "../components/Property";

let Home = () => {
  const [properties, setProperties] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const response = await propertyDataService.getAll();
      setProperties(response.data);
    }
    fetchData();
  }, []);
  return (
    <>
      <div>
        <Navbar />
      </div>
      <div className="home mt-5 pt-5 px-5">
        <div className="row px-2 mt-5">
          {/* <div className="col-sm-12 col-md-6"><i className="bi bi-geo-alt me-2"></i>Location<i className="bi bi-caret-down"></i></div> */}

          {/* <div className="col-sm-12 col-md-6">
                    <input type="text" placeholder="City, Road, etc eg Waiyaki" class="kyt-input"/>
                    <button class="btn btn-primary">Search</button>
                </div> */}
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Your preferred location e.g. Kilimani"
              aria-label="Recipient's username"
              aria-describedby="button-addon2"
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              id="btn btn-primary "
            >
              Search
            </button>
          </div>
        </div>
        <div className="row mt-5">
          {properties ? (
            <>
              {properties.map((property) => (
                <Property property={property} key={property.id} />
              ))}
            </>
          ) : (
            <p>Loading...</p>
          )}

          {/* <PropertyItem />
                <PropertyItem />
                <PropertyItem />
                <PropertyItem />
                <PropertyItem />
                <PropertyItem />
                <PropertyItem />
                <PropertyItem />
                <PropertyItem />
                <PropertyItem />
                <PropertyItem />
                <PropertyItem />
                <PropertyItem />
                <PropertyItem />
                <PropertyItem /> */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
