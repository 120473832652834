import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const Page404 = () => {

    return ( 
        <>
        <Navbar />
        <div className="page404">
            <h2 className="title">Error 404</h2>
            <p className="text">Page Not Found</p>
            {/* <button onClick={handleClick}>Back</button> */}
            <Link to='/'>
                <button className="btn back-btn">Back to home</button>
            </Link>
        </div>
        <Footer />
        </>
     );
}
 
export default Page404;