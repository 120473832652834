export default function Section2() {
  return (
    <section className="features-1">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-3" data-aos="fade-up" data-aos-delay={300}>
                <div className="box-feature">
                  <span className="flaticon-house" />
                  <h3 className="mb-3">Our Properties</h3>
                  <p>
                  Discover a wide range of properties tailored to suit all your needs. Whether you're looking for a cozy apartment in the city or a spacious family home in the suburbs, we have something for everyone. Our properties are carefully selected and maintained to ensure the highest quality living experience. Start your search today and find your perfect home with us.
                  </p>
                  {/* <p><a href="#" className="learn-more">Learn More</a></p> */}
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3" data-aos="fade-up" data-aos-delay={500}>
                <div className="box-feature">
                  <span className="flaticon-building" />
                  <h3 className="mb-3">Property for Sale</h3>
                  <p>
                  Explore our exclusive listings of properties for sale. We offer a diverse portfolio of homes, from modern condos to luxurious villas. Each property comes with detailed information and high-quality images, making it easy for you to find your dream home. Don't miss out on the opportunity to own a piece of prime real estate. Contact us for more details or to schedule a viewing.
                  </p>
                  {/* <p><a href="#" className="learn-more">Learn More</a></p> */}
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3" data-aos="fade-up" data-aos-delay={400}>
                <div className="box-feature">
                  <span className="flaticon-house-3" />
                  <h3 className="mb-3">Real Estate Agent</h3>
                  <p>
                  Meet our team of professional real estate agents who are dedicated to helping you find your perfect home. Our agents are experienced, knowledgeable, and committed to providing exceptional service. Whether you're buying, selling, or renting, our agents will guide you through every step of the process with personalized attention and expert advice. Get in touch with us today to get started.
                  </p>
                  {/* <p><a href="#" className="learn-more">Learn More</a></p> */}
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3" data-aos="fade-up" data-aos-delay={600}>
                <div className="box-feature">
                  <span className="flaticon-house-1" />
                  <h3 className="mb-3">House for Sale</h3>
                  <p>
                  Looking for a house that feels like home? Our selection of houses for sale includes properties in various styles and sizes, each offering comfort and convenience. Whether you prefer a modern design or a traditional layout, you'll find a house that fits your needs. Explore our listings to discover the perfect house for you and your family.
                  </p>
                  {/* <p><a href="#" className="learn-more">Learn More</a></p> */}
                </div>
              </div>
            </div>
          </div>
        </section>
  )
}
