import { Link } from "react-router-dom";

export default function Section5() {
  return (
    <div className="section">
    <div className="row justify-content-center footer-cta" data-aos="fade-up">
      <div className="col-lg-7 mx-auto text-center">
        <h2 className="mb-4">Be a part of our growing real state agents</h2>
        <p>
          <Link to="#" target="" className="btn btn-primary text-white py-3 px-4">Opening soon</Link>
        </p>
      </div>
      {/* /.col-lg-7 */}
    </div>
    {/* /.row */}
  </div>
  )
}
