// import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
//import AOS from "aos";
//import "aos/dist/aos.css";
// AOS.init();

//import AOSInit from "./assets/js/custom";
//AOSInit(); //testing

// import TinySlider from "tiny-slider-react";
// import 'tiny-slider/dist/tiny-slider.css';

import "./assets/css/App.css";
import Landing from "./pages/Landing";
import Page404 from "./pages/Page404";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import Home from "./pages/Home";
import PropertyDetail from "./pages/PropertyDetail";
// import propertyDataService from "./services/property.service";

function App() {
  // const [properties, setProperties] = useState([]);
  // const cachedProperties = new Map(); //info: caching

  // useEffect(() => {
  //   //AOS.init();
  //   getProperties();
  // }, []);
  // let signedIn = false;

  // const getProperties = () => {
  //   propertyDataService.getAll()
  //     .then(response => {
  //       setProperties(response.data);
  //       //console.log(response.data);
  //     })
  //     .catch(e => {
  //       console.log(e);
  //     });
  // };

  return (
    <div className="App">
      
      <Routes>
        <Route path="" element={<Landing />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/home" element={<Home />} />
        <Route path="/house/:id" element={<PropertyDetail />} />
        <Route path="*" element={<Page404 /> } />
      </Routes>

      
      {/* /.site-footer */}
      {/* Preloader */}
      {/* <div id="overlayer" />
        <div className="loader">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div> */}
    </div>
  );
}

export default App;
