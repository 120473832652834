import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import propertyDataService from "../services/property.service";
import Footer from "../components/Footer";
import img_1 from "../assets/images/img_1.jpg";
import img_2 from "../assets/images/img_2.jpg";
import img_3 from "../assets/images/img_3.jpg";
//import NavbarSticky from "../components/NavbarSticky";
import Navbar from "../components/Navbar";
import { Carousel } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBed,
  faCube,
  faBath,
  faCubes,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";

export default function PropertyDetail() {
  const { id } = useParams();

  const [property, setProperty] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const response = await propertyDataService.get(id);
      // console.log(response.data);
      setProperty(response.data);
    }
    fetchData();
  }, []);

  // Format the price to KES using the locale, style, and currency.
  let Ksh = new Intl.NumberFormat("en-Ke", {
    style: "currency",
    currency: "Ksh",
    maximumFractionDigits: 0
  });

  // property.sizes[0]?.price ?  Ksh.format(property.sizes[0]?.price) : Ksh.format(25000)+"+"
  return (
    <>
    <Navbar />
      {/* <NavbarSticky /> */}
      
      {
        property ? 
        <>
        <div className="property-detail pt-5 mt-5 row">
        <div className="property-content col-md-12 col-lg-8">
          {/* <img src={img_1} alt="Property" className="property-img img-fluid" /> */}
          <Carousel slide={false}>
            <Carousel.Item>
              <img src={property.images[0]?.url} alt="" className="property-img img-fluid"/>
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img src={property.images[1]?.url} alt="" className="property-img img-fluid"/>
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img src={property.images[2]?.url} alt="" className="property-img img-fluid"/>
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
          </Carousel>
          <div className="price mb-2">
            <span>
              {property.sizes[0]?.price ?  Ksh.format(property.sizes[0]?.price) : Ksh.format(25000)+"+"}
              </span>
          </div>
          <div className="row property-numbers">
            <div className="col-12 col-lg-5 card-type1">
              <p>{property.location}</p>
              <p>{property.road} - {property.city}</p>
            </div>
            <div className="col-3 col-lg-2 card-type1">
              <FontAwesomeIcon icon={faBed} />
              <p>{property.sizes[0]?.bed} beds</p>
            </div>
            <div className="col-3 col-lg-2 card-type1">
              <FontAwesomeIcon icon={faBath} />
              <p>{property.sizes[0]?.bath} baths</p>
            </div>
            <div className="col-3 col-lg-2 card-type1">
              <FontAwesomeIcon icon={faCube} />
              <p>{property.sizes[0]?.area} m2 </p>
            </div>
          </div>
          <div className="property-desc">
            <h5 className="title">Description</h5>
            <p className="content">
            {property.description}
            </p>
          </div>
        </div>
        <div className="property-content-side col-md-12 col-lg-4">
          <div className="property-btn-group">
            <button className="btn btn-outline-secondary">
              <FontAwesomeIcon icon={faHeart} /> Save
            </button>
            <Link to="#" className="btn btn-outline-secondary">
              <FontAwesomeIcon icon={faCubes} /> Virtual Tour
            </Link>
          </div>
        </div>
      </div>
        </> : <div><p>Loading...</p></div>
      }
      <Footer />
    </>
  );
}
