import { Link } from "react-router-dom";
// import img_1 from "../assets/images/img_1.jpg";
import truncateString from "../utils/truncateString";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed, faBath } from "@fortawesome/free-solid-svg-icons";

export default function Property({ property }) {
  const maxDescriptionLength = 34; // Set the max length for the brief description
  // console.log(property);

  // Format the price to KES using the locale, style, and currency.
  let Ksh = new Intl.NumberFormat("en-Ke", {
    style: "currency",
    currency: "Ksh",
    maximumFractionDigits: 0
  });

  // console.log(property.price + ": " + Ksh.format(property.price));

  return (
    <div className="property-item col-sm-12 col-md-4 col-lg-3 mb-3">
      <Link to="/house" className="img">
        <img
          src={property.images[0].url}
          // src={img_1}
          alt="Property"
          className="property-img"
          // height={400}
          // width={400}
        />
      </Link>
      <div className="property-content">
        <div className="price mb-2">
          <span>{property.sizes[0]?.price ?  Ksh.format(property.sizes[0]?.price) : Ksh.format(25000)+"+"}</span>
        </div>
        <div>
          <span className="property-brief text-red-50">
          {/* d-block mb-2 text-black-50 */}
            {truncateString(property.brief, maxDescriptionLength)}
          </span>
          {/* <span className="city d-block mb-3">{property.name}</span> */}
          <span className="city d-block mb-3">{property.location}</span>
          <div className="specs d-flex mb-4">
            <span className="d-block d-flex align-items-center me-3">
              {/* <span className="icon-bed me-2" /> */}
              <FontAwesomeIcon icon={faBed} />
              <span className="caption mx-1">2</span>
            </span>
            <span className="d-block d-flex align-items-center">
              {/* <span className="icon-bath me-2" /> */}
              <FontAwesomeIcon icon={faBath} />
              <span className="caption mx-1">2</span>
            </span>
          </div>
          <Link
            to={`/house/${property.id}`}
            className="btn-default btn-default-primary py-2 px-3"
          >
            See details
          </Link>
        </div>
      </div>
    </div>
  );
}
