import hero_bg_3 from "../assets/images/hero_bg_3.jpg";

export default function Section4() {
  return (
    <div className="section section-4 bg-light">
          <div className="container">
            <div className="row justify-content-center text-center mb-5">
              <div className="col-lg-5">
                <h2 className="font-weight-bold heading text-primary mb-4">
                  Let's find a home that's perfect for you
                </h2>
                <p className="text-black-50">
                Discover a wide range of properties that cater to your needs. Our dedicated agents are here to help you every step of the way.
                </p>
              </div>
            </div>
            <div className="row justify-content-between mb-5">
              <div className="col-lg-7 mb-5 mb-lg-0 order-lg-2">
                <div className="img-about dots">
                  <img src={hero_bg_3} alt="Property" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex feature-h">
                  <span className="wrap-icon me-3">
                    <span className="icon-home2"><i className="bi bi-house-heart"></i></span>
                  </span>
                  <div className="feature-text">
                    <h3 className="heading">Diverse Property Listings</h3>
                    <p className="text-black-50">
                    We offer an extensive selection of properties across various locations and price ranges. Whether you’re looking for a cozy apartment, a spacious family home, or a luxurious villa, our listings are designed to meet all your requirements.
                    </p>
                  </div>
                </div>
                <div className="d-flex feature-h">
                  <span className="wrap-icon me-3">
                    <span className="icon-person" ><i className="bi bi-person"></i></span>
                  </span>
                  <div className="feature-text">
                    <h3 className="heading">Top-Rated Real Estate Agents</h3>
                    <p className="text-black-50">
                    Our agents are highly experienced and rated among the best in the industry. They are committed to providing personalized service and expert guidance to help you navigate the real estate market with ease and confidence.
                    </p>
                  </div>
                </div>
                <div className="d-flex feature-h">
                  <span className="wrap-icon me-3">
                    <span className="icon-security" ><i className="bi bi-shield-check"></i></span>
                  </span>
                  <div className="feature-text">
                    <h3 className="heading">Verified and Legitimate Properties</h3>
                    <p className="text-black-50">
                    We prioritize your safety and peace of mind by ensuring all properties listed are verified and legitimate. Our rigorous verification process guarantees that you only deal with trustworthy sellers and properties, making your home-buying experience secure and hassle-free.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row section-counter mt-5">
              <div className="col-6 col-sm-6 col-md-6 col-lg-3" data-aos="fade-up" data-aos-delay={300}>
                <div className="counter-wrap mb-5 mb-lg-0">
                  <span className="number"><span className="countup text-primary">3298</span></span>
                  <span className="caption text-black-50">_ of Buy Properties</span>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-3" data-aos="fade-up" data-aos-delay={400}>
                <div className="counter-wrap mb-5 mb-lg-0">
                  <span className="number"><span className="countup text-primary">2181</span></span>
                  <span className="caption text-black-50">_ of Sell Properties</span>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-3" data-aos="fade-up" data-aos-delay={500}>
                <div className="counter-wrap mb-5 mb-lg-0">
                  <span className="number"><span className="countup text-primary">9316</span></span>
                  <span className="caption text-black-50">_ of All Properties</span>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-3" data-aos="fade-up" data-aos-delay={600}>
                <div className="counter-wrap mb-5 mb-lg-0">
                  <span className="number"><span className="countup text-primary">7191</span></span>
                  <span className="caption text-black-50">_ of Agents</span>
                </div>
              </div>
            </div> */}
          </div>
        </div>
  )
}
