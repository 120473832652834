import { Link } from "react-router-dom";

let Navbar = () => {
  /* Set the width of the side navigation to 250px */
  function openNav() {
    document.getElementById("mySidenav").style.width = "80%";
  }

  /* Set the width of the side navigation to 0 */
  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  return (
    <nav className="site-nav">
      <div className="container">
        <div className="menu-bg-wrap">
          <div className="site-navigation">
            <Link to="/" className="logo m-0 float-start">
              Kayatrue
            </Link>
            <ul className="js-clone-nav d-none d-lg-inline-block text-start site-menu float-end">
              <li className="active">
                <Link to="/home">Home</Link>
              </li>
              <li className="has-children">
                <a href="#">
                  Houses <i className="bi bi-chevron-down"></i>
                </a>
                <ul className="dropdown">
                  <li>
                    <a href="#">For Rent</a>
                  </li>
                  <li>
                    <a href="#">For Sale</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#">Services</a>
              </li>
              <li>
                <a href="#">About</a>
              </li>
              <li>
                <a href="#">Contact Us</a>
              </li>
              <li>
                <Link to="/signin">Sign In</Link>
              </li>
              <li>
                <Link to="/signup">Get Started</Link>
              </li>
            </ul>
            <a
              
              className="burger light me-auto float-end mt-1 site-menu-toggle js-menu-toggle d-inline-block d-lg-none"
              data-toggle="collapse"
              data-target="_main-navbar"
              onClick={openNav}
            >
              <span />
            </a>
          </div>
        </div>
      </div>
      <div id="mySidenav" className="sidenav">
        <a className="closebtn" onClick={closeNav}>
          &times;
        </a>
        <Link to="/home">Home</Link>
        <Link to="#">Services</Link>
        <Link to="#">About</Link>
        <Link to="#">Contact</Link>
        <Link to="/signin">Sign in</Link>
        <Link to="/signup">Get Started</Link>
      </div>
    </nav>
  );
};

export default Navbar;
