import person_1 from "../assets/images/foto-sushi-jKuch64WZ_o-unsplash.jpg";
import person_2 from "../assets/images/charles-etoroma-95UF6LXe-Lo-unsplash.jpg";
import person_3 from "../assets/images/foto-sushi-ExVlW9b648Q-unsplash.jpg";
import person_4 from "../assets/images/prince-akachi-i2hoD-C2RUA-unsplash.jpg";

export default function Section3() {
  return (
    <div className="section sec-testimonials">
      <div className="container">
        <div className="row mb-5 align-items-center">
          <div className="col-md-6">
            <h2 className="font-weight-bold text-primary mb-4 mb-md-0">
              Customer Says
            </h2>
          </div>
          <div className="col-md-6 text-md-end">
            {/* <div id="testimonial-nav">
              <span className="prev" data-controls="prev">
                Prev
              </span>
              <span className="next" data-controls="next">
                Next
              </span>
            </div> */}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4" />
        </div>
        <div className="row">
          <div className="item col-sm-12 col-md-6 col-lg-4 mb-4">
            <div className="testimonial">
              <img
                src={person_1}
                alt="Property"
                className="img-fluid rounded-circle w-25 mb-4"
              />
              <div className="rate">
                <span className="icon-star text-warning" />
                <span className="icon-star text-warning" />
                <span className="icon-star text-warning" />
                <span className="icon-star text-warning" />
                <span className="icon-star text-warning" />
              </div>
              <h3 className="h5 text-primary mb-4">Lilian Kioko</h3>
              <blockquote>
                <p>
                  “As a Marketing Manager, I was looking for a user-friendly platform to showcase our property listings effectively. This real estate website exceeded our expectations. The sleek design and interactive virtual tours make a significant impact on our clients. It's a game-changer for our marketing strategy.”
                </p>
              </blockquote>
              <p className="text-black-50">Marketing Manager</p>
            </div>
          </div>
          <div className="item col-sm-12 col-md-6 col-lg-4 mb-4">
            <div className="testimonial">
              <img
                src={person_2}
                alt="Property"
                className="img-fluid rounded-circle w-25 mb-4"
              />
              <div className="rate">
                <span className="icon-star text-warning" />
                <span className="icon-star text-warning" />
                <span className="icon-star text-warning" />
                <span className="icon-star text-warning" />
                <span className="icon-star text-warning" />
              </div>
              <h3 className="h5 text-primary mb-4">Julius Wachira</h3>
              <blockquote>
                <p>
                  “As a first-time homebuyer, I was overwhelmed with options until I discovered this website. The easy-to-use interface helped me search for the perfect home within my budget. The virtual tours provided a real sense of the properties, and I found my dream home quickly. Thank you!”
                </p>
              </blockquote>
              <p className="text-black-50">First-time Homebuyer</p>
            </div>
          </div>
          <div className="item col-sm-12 col-md-6 col-lg-4 mb-4">
            <div className="testimonial">
              <img
                src={person_3}
                alt="Property"
                className="img-fluid rounded-circle w-25 mb-4"
              />
              <div className="rate">
                <span className="icon-star text-warning" />
                <span className="icon-star text-warning" />
                <span className="icon-star text-warning" />
                <span className="icon-star text-warning" />
                <span className="icon-star text-warning" />
              </div>
              <h3 className="h5 text-primary mb-4">Kelvin Ouma</h3>
              <blockquote>
                <p>
                  “I've been investing in real estate for years, and I can confidently say that this website streamlines the property search process. The detailed property descriptions and high-quality images made it easy to identify promising investment opportunities. It's a valuable asset for any property investor.”
                </p>
              </blockquote>
              <p className="text-black-50">Property Investor</p>
            </div>
          </div>
          <div className="item col-sm-12 col-md-6 col-lg-4 mb-4">
            <div className="testimonial">
              <img
                src={person_4}
                alt="Property"
                className="img-fluid rounded-circle w-25 mb-4"
              />
              <div className="rate">
                <span className="icon-star text-warning" />
                <span className="icon-star text-warning" />
                <span className="icon-star text-warning" />
                <span className="icon-star text-warning" />
                <span className="icon-star text-warning" />
              </div>
              <h3 className="h5 text-primary mb-4">Esther Hassan</h3>
              <blockquote>
                <p>
                  “This platform has revolutionized how I connect with clients. The ability to create and share stunning virtual tours has made my property listings stand out in the competitive real estate market. It's a must-have tool for any real estate agent.”
                </p>
              </blockquote>
              <p className="text-black-50">Marketing Agent</p>
            </div>
          </div>
        </div>
        {/* <div className="testimonial-slider-wrap">
              <div className="testimonial-slider">
              <div className="item col-sm-12 col-md-4 border border-warning m-2">
                  <div className="testimonial">
                    <img src={person_1} alt="Property" className="img-fluid rounded-circle w-25 mb-4" />
                    <div className="rate">
                      <span className="icon-star text-warning" />
                      <span className="icon-star text-warning" />
                      <span className="icon-star text-warning" />
                      <span className="icon-star text-warning" />
                      <span className="icon-star text-warning" />
                    </div>
                    <h3 className="h5 text-primary mb-4">James Smith</h3>
                    <blockquote>
                      <p>
                        “Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts. Separated they live in Bookmarksgrove right at the
                        coast of the Semantics, a large language ocean.”
                      </p>
                    </blockquote>
                    <p className="text-black-50">Designer, Co-founder</p>
                  </div>
                </div>
                <div className="item col-sm-12 col-md-4 border border-warning m-2">
                  <div className="testimonial">
                    <img src={person_2} alt="Property" className="img-fluid rounded-circle w-25 mb-4" />
                    <div className="rate">
                      <span className="icon-star text-warning" />
                      <span className="icon-star text-warning" />
                      <span className="icon-star text-warning" />
                      <span className="icon-star text-warning" />
                      <span className="icon-star text-warning" />
                    </div>
                    <h3 className="h5 text-primary mb-4">Mike Houston</h3>
                    <blockquote>
                      <p>
                        “Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts. Separated they live in Bookmarksgrove right at the
                        coast of the Semantics, a large language ocean.”
                      </p>
                    </blockquote>
                    <p className="text-black-50">Designer, Co-founder</p>
                  </div>
                </div>
                <div className="item col-sm-12 col-md-4 border border-warning m-2">
                  <div className="testimonial">
                    <img src={person_3} alt="Property" className="img-fluid rounded-circle w-25 mb-4" />
                    <div className="rate">
                      <span className="icon-star text-warning" />
                      <span className="icon-star text-warning" />
                      <span className="icon-star text-warning" />
                      <span className="icon-star text-warning" />
                      <span className="icon-star text-warning" />
                    </div>
                    <h3 className="h5 text-primary mb-4">Cameron Webster</h3>
                    <blockquote>
                      <p>
                        “Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts. Separated they live in Bookmarksgrove right at the
                        coast of the Semantics, a large language ocean.”
                      </p>
                    </blockquote>
                    <p className="text-black-50">Designer, Co-founder</p>
                  </div>
                </div>
                <div className="item col-sm-12 col-md-4 border border-warning m-2">
                  <div className="testimonial">
                    <img src={person_4} alt="Property" className="img-fluid rounded-circle w-25 mb-4" />
                    <div className="rate">
                      <span className="icon-star text-warning" />
                      <span className="icon-star text-warning" />
                      <span className="icon-star text-warning" />
                      <span className="icon-star text-warning" />
                      <span className="icon-star text-warning" />
                    </div>
                    <h3 className="h5 text-primary mb-4">Dave Smith</h3>
                    <blockquote>
                      <p>
                        “Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts. Separated they live in Bookmarksgrove right at the
                        coast of the Semantics, a large language ocean.”
                      </p>
                    </blockquote>
                    <p className="text-black-50">Designer, Co-founder</p>
                  </div>
                </div>
              </div>
            </div> */}
      </div>
    </div>
  );
}
